import * as React from 'react';
import {GridDensity} from '../soul/grid/GridDensity';
import GridItem from '../soul/grid/GridItem';
import SpeakerCard from './SpeakerCard';
import Grid from '../soul//grid/Grid';
import {Speaker} from '../../models/Speaker';
import {GridDirection} from "../soul/grid/GridDirection";
import {GVAlignment} from "../soul/grid/GridAlignment";
import {fullSpanOnMobile} from '../soul/grid/GridItemSpan';
import {GridBreakpoint} from '../soul/grid/GridBreakpoint';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersGridProps {
    speakers: Speaker[];
}

const SpeakersGrid: React.FC<SpeakersGridProps> = ({speakers}) => (
    <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} vAlignment={GVAlignment.STRETCH} wrap={true}>
        {speakers
            .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
            .map(speaker => (
            <GridItem colSpan={3} key={speaker.id} responsive={[
                {
                    size: 4,
                    breakpoint: [GridBreakpoint.M]
                },
                {
                    size: 6,
                    breakpoint: [GridBreakpoint.S]
                },
                {
                    size: 12,
                    breakpoint: [GridBreakpoint.XS]
                }
            ]}>
                <SpeakerCard relativePath={speaker.relativePath} speaker={speaker}/>
            </GridItem>
        ))}
    </Grid>
);

export default SpeakersGrid;
