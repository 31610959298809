import * as React from 'react';
import {graphql} from 'gatsby';
import SpeakersGrid from '../../components/speaker/SpeakersGrid';
import {Speaker, SpeakerInSpeechDAO} from '../../models/Speaker';
import {Section} from "../../components/Section";
import NorthAmericaBasePageLayout from "../../layouts/NorthAmericaBasePageLayout";
import {IGatsbyImageData} from "gatsby-plugin-image";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakersPageProps {
    data: {
        allFile: {
            nodes: SpeakerInSpeechDAO[]
        }
    },
    avatar: IGatsbyImageData;
}

const SpeakersPage: React.FC<SpeakersPageProps> = ({data}) => {
    const speakers = data.allFile.nodes
        .map(Speaker.deserializeSpeakerInSpeech)
        .sort((a, b) => a.lastName.localeCompare(b.lastName));

    return (
        <NorthAmericaBasePageLayout location='/north-america/speakers'>
            <Section>
                <h1 className="soul-heading soul-heading--h1">Meet our speakers</h1>
            </Section>
            <Section>
                <SpeakersGrid speakers={speakers}/>
            </Section>
        </NorthAmericaBasePageLayout>
    );
};

export const pageQuery = graphql`
    query NorthAmericaSpeakers {
        allFile(
            filter: {relativeDirectory: {eq: "north-america/speakers"}, extension: {eq: "md"}},
            sort: {fields: [childMarkdownRemark___frontmatter___lastName], order: [ASC]}
        ) {
            nodes {
                id
                childMarkdownRemark {
                    fields {
                        slug
                    }
                    frontmatter {
                        firstName
                        lastName
                        avatar {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        jobTitle
                        organization
                    }
                }
            }
        }
    }
`;

export default SpeakersPage;
