import * as React from 'react';
import {Speaker} from '../../models/Speaker';
import Image from "../Image";
import SLink from "../soul/link/SLink";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerCardProps {
    relativePath: string;
    speaker: Speaker;
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({relativePath, speaker}) =>
    <SLink to={relativePath} className="soul-card soul-card--flat soul-card--comfortable soul-card--fill-height">
        <div className="soul-card__header esteco-center-content">
            <div className="soul-avatar soul-avatar--user soul-avatar--xl">
                <div className="soul-avatar__thumbnail">
                    <Image imgObject={speaker.avatar} className="soul-avatar__image" alt={speaker.fullName}/>
                </div>
            </div>
        </div>
        <div className="soul-card__content-fill-height">
            <div className="soul-content soul-font-size-m">
                <span className="soul-label soul-label--strong">{speaker.fullName}</span>
                <span className="soul-font-size-s soul-label soul-label--reduced word-break">{speaker.jobTitle}</span>
                <span className="soul-font-size-xs soul-label soul-label--reduced h-text-bold word-break">{speaker.organization}</span>
            </div>
        </div>
    </SLink>
;

export default SpeakerCard;
